$black: #000;
$white: #fff;
$border-color: rgba(255, 255, 255, 0.1);

@font-face {
  font-family: 'Avenir';
  src: local('Avenir'), url('../fonts/Avenir-Roman.otf') format('truetype');
}

* {
  box-sizing: border-box;
  font-family: 'Avenir', sans-serif;
}

html {
  height: 100%;
  width: 100%;
  overflow-x: hidden;
}

body {
  height: 100%;
  margin: 0;
  text-align: center;
  font-weight: normal;
  background: linear-gradient(180deg, #202328 0%, #0F131D 100%), #080C17;
  background-repeat: no-repeat;
  background-attachment: fixed;
  color: $white;
}

body {
  padding: 45px 10px 0 10px;

  @media screen and (max-width: 400px) {
    padding-left: 10px;
    padding-right: 10px;
  }
}

a {
  color: inherit;
}

hr {
  border: none;
  height: 1px;
  margin: 10px -20px;
  background: $border-color;
}

.games {
  padding: 10px;
  max-width: 600px;
  margin: auto;

  &__header {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;

    img {
      width: 123px;
    }
  }

  @media screen and (max-width: 360px) {
    padding: 5px;
  }

  .games-category {
    margin-bottom: 24px;

    .horizontal-list {
      display: flex;
      flex-wrap: nowrap;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
      -ms-overflow-style: -ms-autohiding-scrollbar;

      &::-webkit-scrollbar {
        display: none;
      }

      > div {
        margin-right: 5px;
      }
      :last-child {
        margin-right: 0;
      }

      .card {
        flex: 0 0 auto;
        cursor: pointer;
        width: 102px;
        user-select: none;
        -webkit-tap-highlight-color: transparent;

        &__img {
          height: 150px;
          border-radius: 5px;
          background-size: cover;
          background-position: center center;
        }

        &__title {
          text-align: left;
          margin-top: 12px;
          font-size: 14px;
        }
      }
    }

    label {
      display: flex;
      justify-content: flex-start;
      font-size: 18px;
      font-weight: 800;
      margin-bottom: 6px;
    }
  }
}

.top-games-slick {
  max-width: 500px;
  margin: 0 auto 30px auto;

  .top-game {
    cursor: pointer;
    min-height: 190px;
    max-height: 220px;
    margin: auto;
    border-radius: 5px;
    background-size: cover;
    background-position: center center;

    @media screen and (max-width: 320px) {
      min-height: 155px;
    }
  }
}

.search {
  display: flex;
  align-items: center;
  border-radius: 20px;
  width: 100%;
  padding: 8px 12px 8px 12px;
  border: 1px solid $border-color;
  margin-top: 16px;
  margin-bottom: 24px;

  input {
    color: $white;
    font-size: 14px;
    background: unset;
    outline-width: 0;
    border: 0;
    width: 100%;
    margin-left: 10px;
  }
}

.filter {
  cursor: pointer;
  font-size: 14px;
  justify-self: left;

  .options {
    position: absolute;
    padding: 16px;
    background-color: #1D2026;
    border: 1px solid $border-color;
    box-sizing: border-box;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.08);
    border-radius: 10px;
    z-index: 2;

    li {
      text-align: left;
      list-style: none;
      font-size: 16px;
      margin-bottom: 16px;
    }

    li:last-child {
      margin-bottom: 0;
    }

    &__closed {
      display: none;
    }
  }
}
